import React, { Component } from "react";
import { Route } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import CreateJob from "./components/CreateJob";
import ViewDetails from "./components/ViewDetails";
import AdminHome from "./admin/AdminHome";
import Edit from "./admin/Edit";

import { withAuthenticationRequired } from "@auth0/auth0-react";

import { QueryClient, QueryClientProvider } from "react-query";

import "./custom.css";

const queryClient = new QueryClient();

export default function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Layout>
                <Route exact path="/" component={Home} />
                <Route path="/add-job" component={CreateJob} />
                <Route path="/details/:id" component={ViewDetails} />
                <Route
                    path="/admin"
                    component={withAuthenticationRequired(AdminHome)}
                />
                <Route path="/edit/:id" component={withAuthenticationRequired(Edit)} />
            </Layout>
        </QueryClientProvider>
    );
}
