import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logoImg from "../images/cc3Logo_white.png";

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" dark style={{background: "slategray"}}>
                    <Container fluid={true}>
                        <NavbarBrand tag={Link} to="/"><img src={logoImg} width="30" alt="CC3 Logo" style={{margin: "0 10px"}} />Information Systems Request Portal</NavbarBrand>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
