import React from "react";
import { useParams, Link } from "react-router-dom";
import { ArrowBarLeft } from "react-bootstrap-icons";
import Moment from "react-moment";
import { Jumbotron } from "reactstrap";
import useAxios from "axios-hooks";
import BeatLoader from "react-spinners/BeatLoader";

const ViewDetails = () => {
    const { id } = useParams();


    const [{ data, loading, error }] = useAxios(
        `/api/jobs/${id}`
    )

    function base64ToArrayBuffer(data) {
        var bString = window.atob(data);
        var bLength = bString.length;
        var bytes = new Uint8Array(bLength);
        for (var i = 0; i < bLength; i++) {
            var ascii = bString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };

    let generateFile = (content, fileName) => {
        const bufferArray = base64ToArrayBuffer(content)
        const blob = new Blob([bufferArray]);

        const myAnchor = document.createElement('a')
        myAnchor.href = window.URL.createObjectURL(blob)
        myAnchor.download = fileName
        myAnchor.click()
        return myAnchor

    }

        if (loading)
            return (
                <div style={{ padding: "300px 0", textAlign: "center" }}>
                    <BeatLoader color="dodgerblue" />
                </div>
        );

        if (error) return "An error has occured: " + error.message;

        return (
            <>
                <Jumbotron
                    style={{ boxShadow: "0 0 10px rgb(189, 189, 189)" }}
                    className="mx-auto detailsJumbo"
                >
                    <div className="text-center container">
                        <div
                            style={{ width: "100%", padding: "2rem", borderRadius: "20px", boxShadow: `0 0 30px rgba(112,128,144,0.5)` }}
                            className={
                                data.priorityColor == "red"
                                    ? "bg-danger"
                                    : data.priorityColor == "green"
                                        ? "bg-success"
                                        : "bg-warning"
                            }
                        >
                            <h3 className="text-secondary">Job Title:</h3>
                            <h3>{data.jobTitle.toUpperCase()}</h3>
                        </div>
                        <br />
                        <h4 className="text-secondary">Priority Color</h4>
                        {data.priorityColor === "red" ? (
                            <div
                                className="bg-danger mx-auto"
                                style={{ width: "20px", height: "20px", borderRadius: "50%" }}
                            ></div>
                        ) : data.priorityColor === "green" ? (
                            <div
                                className="bg-success mx-auto"
                                style={{ width: "20px", height: "20px", borderRadius: "50%" }}
                            ></div>
                        ) : (
                            <div
                                className="bg-warning mx-auto"
                                style={{ width: "20px", height: "20px", borderRadius: "50%" }}
                            ></div>
                        )}
                        <br />
                        <h4 className="text-secondary">Job Description:</h4>
                        <p>{data.jobDescription}</p>
                        <br />
                        <h4 className="text-secondary">Job Manager:</h4>
                        <h4>{data.assignedWorker}</h4>
                        <br />
                        <h4 className="text-secondary">Current Status:</h4>
                        <h4>{data.currentStatus}</h4>
                        <br />
                        <h4 className="text-secondary">Start Date:</h4>
                        <h4>
                            {" "}
                            {data.jobStartDate == "0001-01-01T00:00:00" ? (
                                "Waiting for Approval"
                            ) : (
                                <Moment format="YYYY/MM/DD">{data.jobStartDate}</Moment>
                            )}
                        </h4>
                        <br />
                        <h4 className="text-secondary">Estimated Completion Date:</h4>
                        <h4>
                            {" "}
                            {data.jobEndDate == "0001-01-01T00:00:00" ? (
                                "Waiting for Approval"
                            ) : (
                                <Moment format="YYYY/MM/DD">{data.jobEndDate}</Moment>
                            )}
                        </h4>
                        <br />
                        <br />
                        <h4 className="text-secondary">Job Request Created By: </h4>
                        <h4>{data.createdBy}</h4>
                        {
                            data.fileName != null ? <><br /><h4 className="text-secondary">Attachment(s):</h4><button type="button" className="btn btn-link" onClick={() => generateFile(data.addFile, data.fileName)}>{data.fileName}</button></> : ""
                        }
                        <hr />
                        <h5 className="text-secondary">Job Created:</h5>
                        <h5>
                            <Moment format="MM-DD-YYYY">{data.jobCreationDate}</Moment>
                        </h5>
                        <br />
                        <div>
                            <Link to="/">
                                <ArrowBarLeft />
                                Back to Home
                            </Link>
                        </div>
                    </div>
                </Jumbotron>
            </>
        );
};

export default ViewDetails;
