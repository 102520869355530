import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ArrowBarLeft } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { Container } from "reactstrap";
import * as Yup from "yup";

const CreateJob = () => {
    const history = useHistory();
    const [submitMessage, setSubmitMessage] = useState("");

    const [jobInfo, setJobInfo] = useState([]);

    const dropDownNums = [];
    for (let i = 1; i <= 50; i++) {
        dropDownNums.push(i);
    }

    useEffect(() => {
        fetch("/api/jobs")
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setJobInfo(data);
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            jobTitle: ``,
            jobDescription: ``,
            priorityColor: ``,
            assignedWorker: ``,
            createdBy: ``,
            addFile: null
        },
        validationSchema: Yup.object({
            jobTitle: Yup.string().required("Required"),
            jobDescription: Yup.string().required("Required"),
            priorityColor: Yup.string().required("Required"),
            createdBy: Yup.string().required("Required")
        }),
        onSubmit: (e) => {

            function convertFile(file) {
                const reader = new FileReader()

                reader.onloadend = () => {

                    const someFile = reader.result.replace("data:", "").replace(/^.+,/, "")

                    fetch("/api/jobs", {
                        method: "POST",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            jobTitle: e.jobTitle,
                            jobDescription: e.jobDescription,
                            priorityColor: e.priorityColor,
                            createdBy: e.createdBy,
                            addFile: someFile,
                            fileName: e.addFile.name
                        }),
                    }).then(() => {
                        setSubmitMessage(
                            "Your job has been submitted and sent off for approval! You will now be redirected back to the Home Page. Thanks!"
                        );
                        setTimeout(() => {
                            history.push("/");
                        }, 5000);
                    });
                }
                reader.readAsDataURL(file)
            }

            if (e.addFile !== null) {
                convertFile(e.addFile)
            } else {
                fetch("/api/jobs", {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    credentials: "same-origin",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        jobTitle: e.jobTitle,
                        jobDescription: e.jobDescription,
                        priorityColor: e.priorityColor,
                        createdBy: e.createdBy
                    }),
                }).then(() => {
                    setSubmitMessage(
                        "Your job has been submitted and sent off for approval! You will now be redirected back to the Home Page. Thanks!"
                    );
                    setTimeout(() => {
                        history.push("/");
                    }, 5000);
                });
            }

            

            
        
        },
    });



    return (
        <>
            <Helmet>
                <title>Create Job - IS Request Portal</title>
            </Helmet>
            <Container>
                <h2 className="text-uppercase">Create New Job</h2>
                <div className="d-flex justify-content-end">
                    <Link to="/">
                        <ArrowBarLeft />
                        Back to Home
                    </Link>
                </div>
                {submitMessage ? (
                    <div
                        className="text-center my-3 d-flex flex-column justify-content-center align-items-center"
                        style={{ height: `600px` }}
                    >
                        <BeatLoader color="dodgerblue" />
                        <h3>{submitMessage}</h3>
                    </div>
                ) : (
                    <form onSubmit={formik.handleSubmit} >

                        <div className="form-group row">
                            <label
                                htmlFor="jobTitle"
                                className="col-sm-2 col-form-label"
                                style={{ fontWeight: "bold" }}
                            >
                                *Job Title: <br />
                                <small style={{ color: "slategray" }}>
                                    (ex. Update CC3 Website)
                                </small>
                            </label>
                            <div className="col-sm-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="jobTitle"
                                    id="jobTitle"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.jobTitle}
                                />
                                {formik.touched.jobTitle && formik.errors.jobTitle ? (
                                    <div className="text-danger" style={{ fontWeight: "bold" }}>
                                        {formik.errors.jobTitle}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor="jobDescription"
                                className="col-sm-2 col-form-label"
                                style={{ fontWeight: "bold" }}
                            >
                                *Job Description:
                                <br />
                                <small style={{ color: "slategray" }}>
                                    (Please be as detailed as possible.)
                                </small>
                            </label>
                            <div className="col-sm-6">
                                <textarea
                                    className="form-control"
                                    style={{ width: "100%", height: "400px" }}
                                    type="text"
                                    name="jobDescription"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.jobDescription}
                                />
                                {formik.touched.jobDescription &&
                                    formik.errors.jobDescription ? (
                                    <div className="text-danger" style={{ fontWeight: "bold" }}>
                                        {formik.errors.jobDescription}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor="assignedWorker"
                                className="col-sm-2 col-form-label"
                            >
                                Assigned IS Specialist
                            </label>
                            <h3
                                className="custom-select col-sm-2 ml-sm-3"
                                name="assignedWorker"
                            >
                                Justin/Chris/Dan
                            </h3>
                            <small className="form-text text-muted col-sm-4">
                                (*IS Specialist will be assigned during approval.)
                            </small>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label" style={{ fontWeight: "bold" }}>
                                *Priority (Choose One):
                            </label>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    id="topPriority"
                                    name="priorityColor"
                                    value="red"
                                    className="custom-control-input"
                                    onChange={formik.handleChange}
                                />
                                <label className="custom-control-label" htmlFor="topPriority">
                                    Red (Top Priority)
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    id="midPriority"
                                    name="priorityColor"
                                    value="gold"
                                    className="custom-control-input"
                                    onChange={formik.handleChange}
                                />
                                <label className="custom-control-label" htmlFor="midPriority">
                                    Yellow (High Priority)
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    id="lowPriority"
                                    name="priorityColor"
                                    value="green"
                                    className="custom-control-input"
                                    onChange={formik.handleChange}
                                />
                                <label className="custom-control-label" htmlFor="lowPriority">
                                    Green (Low Priority)
                                </label>
                            </div>
                            {formik.touched.priorityColor && formik.errors.priorityColor ? (
                                <div className="text-danger" style={{ fontWeight: "bold" }}>
                                    {formik.errors.priorityColor}
                                </div>
                            ) : null}
                            <small className="form-text text-muted col-sm-4">
                                (*Priority may be determined during approval.)
                            </small>
                        </div>
                        {/* Add Attachments */}
                        <div className="row">
                            <div className="col-2">
                                <label
                                    htmlFor="addFile"
                                    style={{ fontWeight: "bold" }}
                                >
                                    Add Attachment(s):
                                </label>
                            </div>
                            <div className="col-2">
                                <input
                                    type="file"
                                    name="addFile"
                                    id="addFile"
                                    onChange={e => {
                                        formik.setFieldValue("addFile", e.currentTarget.files[0])
                                    }}
                                    onBlur={formik.handleBlur}
                                />

                                {formik.touched.addFile && formik.errors.addFile ? (
                                    <div className="text-danger" style={{ fontWeight: "bold" }}>
                                        {formik.errors.addFile}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor="createdBy"
                                className="col-sm-2 col-form-label"
                                style={{ fontWeight: "bold" }}
                            >
                                *Job Request Created By: <br />
                                <small style={{ color: "slategray" }}>
                                    (Your First and Last Name)
                                </small>
                            </label>
                            <div className="col-sm-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="createdBy"
                                    id="createdBy"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.createdBy}
                                />
                                {formik.touched.createdBy && formik.errors.createdBy ? (
                                    <div className="text-danger" style={{ fontWeight: "bold" }}>
                                        {formik.errors.createdBy}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {!submitMessage ? (
                            <button type="submit" className="btn btn-primary">
                                Submit Job
                            </button>
                        ) : (
                            <button className="btn btn-primary" type="button" disabled>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>{" "}
                                <span>Submitting</span>
                            </button>
                        )}
                        <br />
                        <br />
                        <div>
                            <small style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                                (*Note: Job Start Date and Estimated Completion Date Will Be
                                Provided During Approval.)
                            </small>
                        </div>
                    </form>
                )}
            </Container>
        </>
    );
};

export default CreateJob;
