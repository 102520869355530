import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import "../src/css/styles.css";

import { Auth0Provider } from "@auth0/auth0-react";


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Auth0Provider domain="dev-omib59sb.us.auth0.com" clientId="P3ERt9bEajSPVjm6xaoAtKiNArFIDKd8" redirectUri="https://request.cc3solutions.com/admin">
      <App />
    </Auth0Provider>
  </BrowserRouter>,
  rootElement);

//registerServiceWorker();

