import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Plus, BoxArrowLeft, Trash, PencilSquare } from "react-bootstrap-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import { useQuery } from "react-query";
/*import useAxios from "axios-hooks";*/

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import BeatLoader from "react-spinners/BeatLoader";
import Footer from "../components/Footer";

const AdminHome = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [curId, setCurId] = useState("");

  const { logout, user } = useAuth0();

   const { isLoading, error, data, isFetching, refetch } = useQuery(
     "repoData",
     () => fetch(`/api/jobs`).then((res) => res.json())
   );

    //const [{ data, loading, error }, refetch] = useAxios(
    //    'https://request.cc3solutions.com/api/jobs', { useCache: false }
    //);

  const handleDelete = (id) => {
    toggle();
    setCurId(id);
  };

  const completeDelete = () => {
    toggle();
    fetch(`/api/jobs/${curId}`, {
      method: "DELETE",
    })
      .then((success) => {
        refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

    if (isLoading)
    return (
      <div style={{ padding: "300px 0", textAlign: "center" }}>
        <BeatLoader color="dodgerblue" />
      </div>
    );
    if (error) return "An error has occured: " + error.message;
    if (isFetching)
     return (
       <div style={{ padding: "300px 0", textAlign: "center" }}>
         <BeatLoader color="dodgerblue" />
       </div>
     );

  return (
    <div>
      <div>
        <Helmet>
          <title>IS Request Admin Portal</title>
        </Helmet>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <p style={{ margin: "0 10px" }}>
          Welcome, <span style={{ fontWeight: "bold" }}>{user.name}</span>!
        </p>
        {/* <Link
          role="button"
          to="/add-job"
          className="btn btn-success mx-3"
          style={{ marginBottom: "2rem" }}
        >
          <Plus color="white" size="30" />
          Create New Job
        </Link> */}
        <Link
          role="button"
          to="/"
          className="btn btn-primary"
          onClick={() => logout()}
        >
          <BoxArrowLeft color="white" size="20" /> Logout
        </Link>
      </div>
      <div className="text-center">
        <h3>IS Request Admin Portal</h3>
      </div>
      {data.length == 0 ? (
        <div className="text-center">
          <hr />
          <h1>No Current Jobs</h1>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Job #</th>
                <th scope="col">Priority</th>
                <th scope="col">Job Title</th>
                <th scope="col">Job Manager</th>
                <th scope="col">Current Status</th>
                <th scope="col">Start Date</th>
                <th scope="col">Estimated Completion Date</th>
                <th scope="col">Edit/Delete</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => {
                return (
                  <tr
                    key={i}
                    className={
                      item.priorityColor === "green"
                        ? "bg-success"
                        : item.priorityColor === "red"
                        ? "bg-danger"
                        : "bg-warning"
                    }
                  >
                    <th scope="row">{item.id}</th>
                    <td>
                      <div
                        style={{
                          background: item.priorityColor,
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </td>
                    <td>{item.jobTitle}</td>
                    <td>{item.assignedWorker}</td>
                    <td>{item.currentStatus}</td>
                    <td>
                      {item.jobStartDate == "0001-01-01T00:00:00" ? (
                        "Waiting for Approval"
                      ) : (
                        <Moment format="YYYY/MM/DD">{item.jobStartDate}</Moment>
                      )}
                    </td>
                    <td>
                      {item.jobEndDate == "0001-01-01T00:00:00" ? (
                        "Waiting for Approval"
                      ) : (
                        <Moment format="YYYY/MM/DD">{item.jobEndDate}</Moment>
                      )}
                    </td>
                    <td>
                      <div className="d-flex justify-content-around">
                        <Link
                          role="button"
                          className="btn btn-secondary"
                          style={{ padding: "0 3rem" }}
                          to={`/edit/${item.id}`}
                        >
                          <PencilSquare color="white" size="20" />
                        </Link>

                        <button
                          className="btn btn-danger"
                          style={{ padding: "0 3rem" }}
                          onClick={() => handleDelete(item.id)}
                        >
                          <Trash color="white" size="20" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Delete Job?</ModalHeader>
            <ModalBody>Are you sure you want to delete this job?</ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={() => completeDelete()}>
                Delete
              </Button>{" "}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default AdminHome;
