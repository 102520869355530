import React from "react";
import { Link } from "react-router-dom";
import { Plus, GearWideConnected, Info, Paperclip } from "react-bootstrap-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import { useQuery } from "react-query";
import useAxios from "axios-hooks";
import BeatLoader from "react-spinners/BeatLoader";

export const Home = () => {
    // Auth0
    const { loginWithRedirect } = useAuth0();

    const { isLoading, error, data, isFetching } = useQuery("repoData", () =>
        fetch(`/api/jobs`).then((res) => res.json())
    );

    //  const [{ data, loading, error }, refetch] = useAxios(
    //      "https://request.cc3solutions.com/api/jobs", { useCache: false}
    //);

    //console.log(data)

    if (isLoading)
        return (
            <div style={{ padding: "300px 0", textAlign: "center" }}>
                <BeatLoader color="dodgerblue" />
            </div>
        );
    if (error) return "An error has occured: " + error.message;
    if (isFetching)
        return (
            <div style={{ padding: "300px 0", textAlign: "center" }}>
                <BeatLoader color="dodgerblue" />
            </div>
        );

    return (
        <>
            <div>
                <Helmet>
                    <title>IS Request Portal</title>
                </Helmet>
            </div>
            <div className="d-flex justify-content-end">
                <Link
                    role="button"
                    to="/add-job"
                    className="btn btn-success mx-3"
                    style={{ marginBottom: "2rem" }}
                >
                    <Plus color="white" size="30" />
                    Create New Job
                </Link>
                <Link
                    role="button"
                    to="/admin"
                    className="btn btn-primary"
                    style={{ marginBottom: "2rem" }}
                    onClick={() => loginWithRedirect()}
                >
                    <GearWideConnected color="white" size="20" /> Admin
                </Link>
            </div>
            <div className="d-flex justify-content-between">
                <h6 className="d-flex align-items-center">
                    <div
                        className="bg-success"
                        style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            display: "inline-block",
                        }}
                    ></div>{" "}
                    = Low Priority&nbsp;
                    <div
                        className="bg-warning"
                        style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            display: "inline-block",
                        }}
                    ></div>{" "}
                    = High Priority&nbsp;
                    <div
                        className="bg-danger"
                        style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            display: "inline-block",
                        }}
                    ></div>{" "}
                    = Top Priority
                </h6>
            </div>
            {data.length == 0 ? (
                <div className="text-center">
                    <hr />
                    <h1>No Current Jobs</h1>
                </div>
            ) : (
                <div
                    className="table-responsive"
                    style={{
                        boxShadow: `0px 0px 20px rgba(112,128,144,0.5)`,
                        borderRadius: `10px`,
                    }}
                >
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                {/* <th scope="col">#</th> */}
                                <th scope="col">Priority</th>
                                <th scope="col">Job Title</th>
                                <th scope="col">Job Manager</th>
                                <th scope="col">Current Status</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">Estimated Completion Date</th>
                                <th scope="col">View Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, i) => {
                                return (
                                    <tr
                                        key={i}
                                        className={
                                            item.priorityColor === "green"
                                                ? "bg-success"
                                                : item.priorityColor === "red"
                                                    ? "bg-danger"
                                                    : "bg-warning"
                                        }
                                    >
                                        {/* <th scope="row">{item.priorityNumber}</th> */}
                                        <td>
                                            <div
                                                style={{
                                                    background: item.priorityColor,
                                                    width: "20px",
                                                    height: "20px",
                                                    borderRadius: "50%",
                                                }}
                                            ></div>
                                        </td>
                                        <td>{item.jobTitle.toUpperCase()}{item.fileName != null ? <Paperclip size={24} /> : ""}</td>
                                        <td>{item.assignedWorker}</td>
                                        <td>{item.currentStatus}</td>
                                        <td>
                                            {item.jobStartDate == "0001-01-01T00:00:00" ? (
                                                "Waiting for Approval"
                                            ) : (
                                                <Moment format="YYYY/MM/DD">{item.jobStartDate}</Moment>
                                            )}
                                        </td>
                                        <td>
                                            {item.jobEndDate == "0001-01-01T00:00:00" ? (
                                                "Waiting for Approval"
                                            ) : (
                                                <Moment format="YYYY/MM/DD">{item.jobEndDate}</Moment>
                                            )}
                                        </td>
                                        <td>
                                            <Link
                                                role="button"
                                                className="btn btn-info"
                                                to={`/details/${item.id}`}
                                                style={{ width: "100px", height: "40px", padding: 0 }}
                                            >
                                                <Info size={40} style={{padding: 0}} />
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};
