import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Footer from "./Footer";

export class Layout extends Component {

    render() {
        return (
            <div>
                <NavMenu />
                <Container fluid={true} style={{minHeight: "100vh"}}>
                    {this.props.children}
                </Container>
                <Footer />
            </div>
        );
    }
}
