import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowBarLeft } from "react-bootstrap-icons";
import { Container } from "reactstrap";
import BeatLoader from "react-spinners/BeatLoader";
import { useHistory, useParams } from "react-router-dom";
import Moment from "react-moment";

const Edit = () => {
    const history = useHistory();
    const [job, setJob] = useState({});
    const [submitMessage, setSubmitMessage] = useState("");

    const [jobInfo, setJobInfo] = useState([]);

    const { id } = useParams();

    useEffect(() => {
        fetch(`/api/jobs/${id}`)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setJob(data);
            });
    }, []);

    const handleChange = (e) => {
        setJob({ ...job, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch(`/api/jobs/${id}`, {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(job),
        }).then(() => {
            setSubmitMessage(
                "Your job has been edited! You will now be redirected back to the Admin Page. Thanks again!"
            );
            setTimeout(() => {
                history.push("/admin");
            }, 5000);
        });
    };

    return (
        <>
            <Container>
                <h2 className="text-uppercase">Edit Job</h2>
                <div className="d-flex justify-content-end">
                    <Link to="/admin">
                        <ArrowBarLeft />
                        Back to Admin Home
                    </Link>
                </div>
                {submitMessage ? (
                    <div
                        className="text-center my-3 d-flex flex-column justify-content-center align-items-center"
                        style={{ height: `600px` }}
                    >
                        <BeatLoader color="dodgerblue" />
                        <h3>{submitMessage}</h3>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <label htmlFor="jobNumber" className="col-sm-2 col-form-label">
                                Job #:
                            </label>
                            {/* <select
              className="custom-select col-sm-2 ml-sm-3"
              name="priorityNumber"
              onChange={handleChange}
              value={job.priorityNumber}
            >
              {[...Array(50)].map((e, i) => {
                return (
                  <option
                    value={i + 1}
                    key={i + 1}
                    className={
                      jobInfo.some((item) => item.priorityNumber == i + 1)
                        ? "text-muted"
                        : "text-primary font-weight-bolder"
                    }
                    disabled={
                      jobInfo.some((item) => item.priorityNumber == i + 1)
                        ? "disabled"
                        : ""
                    }
                  >
                    {i + 1}
                  </option>
                );
              })}
            </select> */}
                            <h4 className="col-sm-2">{job.id}</h4>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="jobTitle" className="col-sm-2 col-form-label">
                                Job Title:
                            </label>
                            <div className="col-sm-3">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="jobTitle"
                                    id="jobTitle"
                                    onChange={handleChange}
                                    value={job.jobTitle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="jobStartDate" className="col-sm-2 col-form-label">
                                Start Date:
                            </label>
                            <input
                                type="date"
                                name="jobStartDate"
                                onChange={handleChange}
                                className="form-control col-sm-2 ml-sm-3"
                            />
                        </div>
                        <div className="form-group row">
                            <label htmlFor="jobEndDate" className="col-sm-2 col-form-label">
                                Estimated Completion Date:
                            </label>
                            <input
                                type="date"
                                name="jobEndDate"
                                onChange={handleChange}
                                className="form-control col-sm-2 ml-sm-3"
                            />
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor="jobDescription"
                                className="col-sm-2 col-form-label"
                            >
                                Job Description:
                            </label>
                            <div className="col-sm-6">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    style={{ width: "100%", height: "400px" }}
                                    name="jobDescription"
                                    onChange={handleChange}
                                    value={job.jobDescription}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor="assignedWorker"
                                className="col-sm-2 col-form-label"
                            >
                                Assigned IS Specialist
                            </label>
                            <select
                                className="custom-select col-sm-3 ml-sm-3"
                                name="assignedWorker"
                                onChange={handleChange}
                            >
                                <option disabled hidden selected>
                                    Select an IS Specialist
                                </option>
                                <option value="Justin">Justin</option>
                                <option value="Chris">Chris</option>
                                <option value="Dan">Dan</option>
                            </select>
                        </div>
                        <div className="form-group row">
                            <label
                                htmlFor="currentStatus"
                                className="col-sm-2 col-form-label"
                            >
                                Current Status
                            </label>
                            <select
                                className="custom-select col-sm-3 ml-sm-3"
                                name="currentStatus"
                                onChange={handleChange}
                                value={job.currentStatus}
                            >
                                <option value="Approved/In-Progress">
                                    Approved/In-Progress
                                </option>
                                <option value="Approved/Not Started">
                                    Approved/Not Started
                                </option>
                                <option value="Not Approved">Not Approved</option>
                                <option value="Waiting For Approval">
                                    Waiting For Approval
                                </option>
                                <option value="Completed">Completed</option>
                                <option value="Paused">Paused</option>
                            </select>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Priority:</label>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    id="topPriority"
                                    name="priorityColor"
                                    value="red"
                                    className="custom-control-input"
                                    onChange={handleChange}
                                    checked={job.priorityColor == "red"}
                                />
                                <label className="custom-control-label" htmlFor="topPriority">
                                    Red (Top Priority)
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    id="midPriority"
                                    name="priorityColor"
                                    value="gold"
                                    className="custom-control-input"
                                    onChange={handleChange}
                                    checked={job.priorityColor == "gold"}
                                />
                                <label className="custom-control-label" htmlFor="midPriority">
                                    Yellow (Mid Priority)
                                </label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input
                                    type="radio"
                                    id="lowPriority"
                                    name="priorityColor"
                                    value="green"
                                    className="custom-control-input"
                                    onChange={handleChange}
                                    checked={job.priorityColor == "green"}
                                />
                                <label className="custom-control-label" htmlFor="lowPriority">
                                    Green (Low Priority)
                                </label>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start row">
                            <div className="col-sm-4">
                                {!submitMessage ? (
                                    <button type="submit" className="btn btn-primary btn-block">
                                        Edit Job
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-primary btn-block"
                                        type="button"
                                        disabled
                                    >
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>{" "}
                                        <span>Submitting</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </Container>
        </>
    );
};

export default Edit;
