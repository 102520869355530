import React from "react";
import Emoji from "a11y-react-emoji";
import donkeyGif from "../images/donkey.gif";

const Footer = () => (
  <>
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        width: "100%",
        marginTop: "2rem"
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100px",
          background: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          padding: "1rem",
        }}
      >
        <h6 style={{ fontWeight: "200" }}>
          CC3 Solutions IS Request Portal - &copy;{new Date().getFullYear()}
        </h6>
        <small>
          Have a suggestion? Email us @{" "}
          <a href="mailto:it@cc3solutions.com">it@cc3solutions.com</a>
        </small>
        <small style={{fontWeight: 'bold'}}>
          {/* <Emoji symbol="👍" /> */}
          Made By The Kick-
          <img src={donkeyGif} width="40" /> IS Team!
        </small>
      </div>
    </div>
  </>
);

export default Footer;
